import { mode } from "@chakra-ui/theme-tools";

const styles = {
    global: (props: any) => ({
        body: {
            bg: mode("gray.50", "gray.800")(props),
            color: mode("gray.900", "gray.50")(props),
        },
    }),
};

export default styles;

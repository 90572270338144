import { extendTheme } from "@chakra-ui/react";

// Global style override
import styles from "./styles";

// Foundational style overrides
import fonts from "./foundations/fonts";
import colors from "./foundations/colors";

const theme = extendTheme({ styles, fonts, colors });
export default theme;
